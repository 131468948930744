<template>
    <div class="container">
        <van-image :src="require('@/assets/fault/banner.png')" />
        <van-tabs v-model:active="active" color="#FFD600" sticky animated>
            <van-tab title="故障产品"></van-tab>
            <van-tab title="我的设备">
                <div class="device">
                    <van-cell
                        v-for="item in devices"
                        :key="item.id"
                        clickable
                        class="item"
                        @click="searchFault(item)"
                    >
                        <template #icon>
                            <van-image
                                :src="require('@/assets/device.png')"
                                class="item-img"
                            />
                        </template>
                        <template #title>
                            <div class="item-name">{{ item.name }}</div>
                        </template>
                        <template #right-icon>
                            <van-icon name="arrow" class="item-icon" />
                        </template>
                    </van-cell>
                </div>
                <div v-if="selectDevice.id" class="search">
                    <div class="title">快速排查</div>
                    <div class="input-view">
                        <van-field
                            v-model="searchValue"
                            placeholder="问题描述"
                            class="input"
                        >
                            <template #left-icon>
                                <van-icon
                                    name="search"
                                    style="margin-right: 10px"
                                />
                            </template>
                        </van-field>
                        <div class="btn" @click="search">搜索</div>
                    </div>
                    <div class="prompt-text">
                        {{ selectDevice.name }} 常见问题：
                    </div>
                    <div class="item-view">
                        <van-button
                            v-for="item in deviceFault"
                            :key="item.id"
                            :to="'faultDetail?id=' + item.id"
                            class="item"
                        >
                            {{ item.name }}
                        </van-button>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, toRefs } from "vue";
export default {
    name: "Fault",
    setup() {
        let { proxy } = getCurrentInstance();
        let state = reactive({
            active: 1,
            devices: [], // 设备列表
            deviceFault: [], // 设备问题列表
            selectDevice: {}, // 选中的设备
            searchValue: "", // 搜索的内容
        });

        function getDevice() {
            state.devices = [
                { id: 1, name: "VOC智能门锁X8" },
                { id: 2, name: "VOC智能门锁X8 华为版" },
            ];
        }
        function getDeviceFault() {
            state.deviceFault = [
                { id: 1, name: "不能添加智能卡" },
                { id: 2, name: "不能添加指纹" },
                { id: 3, name: "无法联网" },
            ];
        }
        function searchFault(device) {
            if (state.selectDevice.id === device.id) return;
            state.selectDevice.id = device.id;
            state.selectDevice.name = device.name;
            getDeviceFault();
        }
        function search() {
            proxy.$dialog.confirm({
                title: "故障查询结果",
                message: "该故障需要专业师傅处理，建议联系专业师傅进行处理",
                theme: "round-button",
                closeOnClickOverlay: true,
                confirmButtonText: "一键报修",
                beforeClose: async (action) => {
                    if (action === "confirm") {
                        proxy.$router.push("fix?active=1");
                        return true;
                    }
                    return true;
                },
            });
        }

        onMounted(() => {
            getDevice();
        });

        return {
            ...toRefs(state),

            searchFault,
            search,
        };
    },
};
</script>

<style lang="less" scoped>
@import url("@/styles/mixin.less");
.container {
    flex: 1;
    background-color: #f2f2f2;
    .van-tabs {
        margin-top: 5px;
    }
    .device {
        margin-top: 10px;
        .item {
            padding: 16px 30px;
            .item-img {
                width: 60px;
                height: 60px;
                box-shadow: 0 0 2px #cccccc;
                border-radius: 5px;
            }
            .item-name {
                color: #999999;
                font-size: 14px;
                align-self: stretch;
                padding: 5px 20px;
            }
            .item-icon {
                align-self: center;
                font-size: 20px;
                color: #cccccc;
            }
        }
    }
    .search {
        margin: 10px;
        padding: 10px 20px;
        background-color: #ffffff;
        .title {
            color: #333;
        }
        .input-view {
            .flex(space-between);
            margin-top: 10px;
            margin-bottom: 15px;
            .input {
                background-color: #f5f5f5;
                border-radius: 5px;
                padding: 5px 16px;
            }
            .btn {
                flex-shrink: 0;
                padding: 10px 20px;
                font-size: 14px;
                color: #999999;
            }
        }
        .prompt-text {
            color: #212121;
            font-size: 12px;
            margin-bottom: 15px;
        }
        .item-view {
            .flex();
            flex-wrap: wrap;
            .item {
                .flex();
                background: #f5f5f5;
                height: 30px;
                border-radius: 15px;
                margin-right: 15px;
                margin-bottom: 10px;
                padding: 0 20px;
                font-size: 12px;
            }
        }
    }
}
</style>